import React, { Component } from 'react';

class ContentEducation extends Component {
    render() {
        return (
            <div className="row content-inside">
                <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                    <div className="experience-box t2 border-corner5 color-s1">
                        <div className="box-inner">
                        <h3 className="f-info color-d3">University of the Punjab</h3>
                        <div className="s-info">BS Environmental Sciences (2015-2020)</div>
                        <p>
                            GIS | Toxicology | EIA | Environmental Law | Ecology | Atmospheric Chemistry 
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.6s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s1">
                        <div className="box-inner">
                            <h3 className="f-info color-d3">2019-2020</h3>
                            <div className="s-info">Environmental Research Associate at Eco Syntec</div>
                            <p>
                            EIA | IEE report writing 
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.7s">
                    <div className="experience-box t2 border-corner5 color-s2">
                        <div className="box-inner">
                        <h3 className="f-info color-d4">COMSATS</h3>
                        <div className="s-info">BS Computer Science (2014-2018)</div>
                        <p>
                            Software Engineering | AI | HCI | Data Structures | Algorithms | Automata Theory 
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s2">
                        <div className="box-inner">
                            <h3 className="f-info color-d4">2018-2021</h3>
                            <div className="s-info">Freelancer at Fiverr</div>
                            <p>
                            Wordpress Developer | UX Designer | Technical Writing  
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.6s">
                    <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                        <h3 className="f-info color-d5">Udacity</h3>
                        <div className="s-info">React Nano-Degree</div>
                        <p>
                            React | Redux | Hooks | React Native 
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.7s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                            <h3 className="f-info color-d5">2020-2021</h3>
                            <div className="s-info">Co-founder at Pakistani Organic Dirt</div>
                            <p>
                            Using food waste to make compost. 
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentEducation;
